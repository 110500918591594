<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <div v-if="showEditSheet">
      <router-view />
    </div>
    <new-sheet />
    <delete-dialog />
    <report-dialog />
    <workflow-run-modal />
    <v-row align="center" justify="space-between" class="mb-2">
      <v-col cols="4">
        <h2 class="headline">
          <v-icon left large color="primary">mdi-domain</v-icon>
          Interactive Activity Objects (IAOs)
        </h2>
      </v-col>
            <template>
        <v-col cols="2" class="text-center">
          <v-select
            v-model="currentStatus"
            :items="statuses"
            item-value="value"
            item-text="label"
            :menu-props="{ 'closeOnContentClick': false }"
            label="Select Status"
            prepend-icon="mdi-filter-menu"
            outlined
            single-line
            dense
          >
            <template v-slot:item="{ item }">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
      </template>
      <v-spacer />
      <v-col cols="auto">
        <v-btn small color="success" class="mr-2" @click="showNewSheet()">
          <v-icon small left>mdi-plus</v-icon>
          New IAO
        </v-btn>
        <table-filter-dialog :projects="defaultUserProjects" />
        <table-export-dialog />
      </v-col>
    </v-row>
    <v-expansion-panels popout>
    <v-row>
      <v-col
        v-for="item in items"
        :key="item.id"
        cols="12"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row no-gutters align="center">
              <!-- Icon and Text Column -->
              <v-col cols="3" class="d-flex align-center">
                <v-icon left color="primary">mdi-domain</v-icon>
                <div>
                  <strong>{{ item.name }}</strong>
                  <div class="mt-2">{{ item.title }}</div>
                </div>
              </v-col>

              <!-- Status, Graph, and Date Columns -->
              <v-col cols="2">
                <incident-status :status="item.status" :id="item.id" />
              </v-col>
              <v-col> <!-- Spacer Column for alignment -->
                <v-spacer></v-spacer>
              </v-col>
              <v-col cols="5">
                <incident-event-graph-min
                  :incident="item"
                  :events="item.events"
                  :style="{ maxWidth: '500px' }"
                />
              </v-col>
              <v-col> <!-- Spacer Column for alignment -->
                <v-spacer></v-spacer>
              </v-col>
              <v-col cols="2">
                <v-chip>
                  <v-icon color="primary" class="mr-1">mdi-calendar</v-icon>
                  {{ item.reported_at | formatRelativeDate }}
                </v-chip>
              </v-col>
            </v-row>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-row>
            <v-col cols="12" sm="6" md="8">
              <v-card class="pa-1 ml-3 mr-2 md-2 d-flex flex-column" outlined>
              <v-card-title class="justify-space-between">
                <div class="d-flex align-center">
                  <v-icon small left>mdi-domain</v-icon>
                  {{ item.name }}
                </div>
                <div class="d-flex align-center">
                  <incident-type :typeName="item.incident_type.name" />
                </div>
                 <!-- AI Summary Button -->
                 <v-spacer />
                <ai-resolution-summary-dialog :summary="item.ai_resolution_summary" />
                <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="item.status === 'Closed'"
                          @click="showRun({ type: 'incident', data: selected })"
                          v-bind="attrs"
                          v-on="on"
                          icon
                          color="tips"
                        >
                          <v-icon>mdi-cog-sync-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Run Workflow</span>
                    </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon :to="{ name: 'IncidentTableEdit', params: { name: item.name }}" v-on="on">
                      <v-icon>mdi-book-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>View/Edit</span>
                </v-tooltip>
              </v-card-title>
              <v-card-text class="flex-grow-1">
                <div class="mb-2"><strong>
                  <v-icon small class="mr-1">mdi-tag</v-icon>
                  Title:</strong> {{ item.title }}</div>
                <div class="mb-2">
                  <v-icon small class="mr-1">mdi-axis-arrow-info</v-icon>
                  <strong>Description:</strong> {{ item.description }}</div>
                  <div v-if="item.resolution" class="mb-2">
                    <v-alert
                      type="success"
                      dense
                      border="left"
                      colored-border
                      class="mb-2"
                      :icon="false"
                    >
                      <strong>BAO Result:</strong> {{ item.resolution }}
                    </v-alert>
                  </div>
                <v-row>
                  <v-col cols="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Activity Lead</v-list-item-title>
                        <v-list-item-subtitle>
                          <incident-participant :participant="item.commander" />
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Cost</v-list-item-title>
                        <v-list-item-subtitle>
                          <incident-cost-card :incident-costs="item.incident_costs" />
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Reporter</v-list-item-title>
                        <v-list-item-subtitle>
                          <incident-participant :participant="item.reporter" />
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Reported</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.reported_at | formatRelativeDate }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Stable</v-list-item-title>
                        <v-list-item-subtitle>
                          <span v-if="item.stable_at">{{ item.stable_at | formatRelativeDate }}</span>
                          <span v-else>Not Stable</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Closed</v-list-item-title>
                        <v-list-item-subtitle>
                          <span v-if="item.closed_at">{{ item.closed_at | formatRelativeDate }}</span>
                          <span v-else>Not Closed</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Visibility</v-list-item-title>
                        <v-list-item-subtitle>
                          <v-chip>
                            <v-icon
                              v-if="item.visibility == 'Open'"
                              color="success"
                              class="mr-1"
                              >mdi-eye-check</v-icon
                            >
                            <v-icon
                              v-if="item.visibility == 'Restricted'"
                              color="warning"
                              class="mr-1"
                              small
                              >mdi-eye-off</v-icon
                            >
                            <span v-if="item.visibility == 'Open'">Public</span>
                            <span v-if="item.visibility == 'Restricted'">Private</span>
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Project</v-list-item-title>
                        <v-list-item-subtitle>
                          <project-card :project="item.project" />
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Priority</v-list-item-title>
                        <v-list-item-subtitle>
                          <incident-priority
                            :priorityName="item.incident_priority.name"
                            :priorityColor="item.incident_priority.color"
                          />
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Severity</v-list-item-title>
                        <v-list-item-subtitle>
                          <incident-severity
                            :severityName="item.incident_severity.name"
                            :severityColor="item.incident_severity.color"
                          />
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="item.status === 'Closed'"
                      @click="showReportDialog(selected)"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="primary"
                    >
                      <v-icon>mdi-send-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Create Comms Report</span>
                </v-tooltip>
                <ai-resolution-summary-dialog :summary="item.ai_resolution_summary" />
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon :to="{ name: 'IncidentTableEdit', params: { name: item.name }}" v-on="on">
                      <v-icon>mdi-book-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>View/Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon
                    @click="markStable(item.id)"
                    :disabled="item.status === 'Closed' || item.status === 'Stable'"
                    :loading="updateLoading"
                    v-on="on">
                      <v-icon>mdi-lock-open-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Mark as Stable</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon
                    @click="markClosed(item.id)"
                    :disabled="item.status === 'Closed'"
                    :loading="updateLoading"
                    v-on="on">
                      <v-icon>mdi-lock-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Mark as Closed</span>
                </v-tooltip>
                <v-menu bottom left class="ml-5">
                  <template v-slot:activator="{ on }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn icon v-on="{ ...on, ...tooltip }">
                          <v-icon color="secondary">mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <span>More Actions</span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-list-item @click="markStable(item.id)" :disabled="item.status == 'Closed' || item.status == 'Stable'">
                      <v-list-item-title>
                        <v-icon class="mr-2">mdi-lock</v-icon>
                        Mark Stable
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="markClosed(item.id)" :disabled="item.status == 'Closed'">
                      <v-list-item-title>
                        <v-icon class="mr-2">mdi-close</v-icon>
                        Mark Closed
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{
                      name: 'IncidentTableEdit',
                      params: { name: item.name },
                    }">
                      <v-list-item-title>
                        <v-icon class="mr-2">mdi-book-edit-outline</v-icon>
                        View / Edit
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="showReportDialog(item)" :disabled="item.status == 'Closed'">
                      <v-list-item-title>
                        <v-icon class="mr-2">mdi-file-document-edit</v-icon>
                        Create Comms Report
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="showRun({ type: 'incident', data: item })"
                      :disabled="item.status == 'Closed'"
                    >
                      <v-list-item-title>
                        <v-icon class="mr-2">mdi-play-circle-outline</v-icon>
                        Run Workflow
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="showDeleteDialog(item)">
                      <v-list-item-title>
                        <v-icon class="mr-2">mdi-delete</v-icon>
                        Delete
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="6" md="4">
            <v-card>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5"> IAO Information </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                {{ item.description }}
              </v-list-item>
              <v-list class="transparent">
                <v-list-item>
                  <v-list-item-title>Data Last Loaded At</v-list-item-title>
                  <v-list-item-subtitle class="text-right">
                    {{ item.created_at | formatRelativeDate }}</v-list-item-subtitle
                  >
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Retention</v-list-item-title>
                  <v-list-item-subtitle class="text-right">
                    {{ item.reported_at | formatRelativeDate }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Avg Prop Delay</v-list-item-title>
                  <v-list-item-subtitle class="text-right"> {{ item.stable_at_at | formatRelativeDate }}</v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
      </v-col>
    </v-row>
      </v-expansion-panels>
    <v-spacer />
    <v-col cols="auto">
      <v-card-title>
        <v-text-field v-model="q" append-icon="search" label="Search" single-line hide-details clearable />
      </v-card-title>
    </v-col>
    <v-data-table
      :items="items"
      :headers="headers"
      :server-items-length="total"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100],
      }"
      :sort-by.sync="sortBy"
      :sort-desc.sync="descending"
      :loading="loading"
      data-testid="incident-data-table"
      v-model="selected"
      loading-text="Loading... Please wait"
      show-select
      @click:row="showIncidentEditSheet"
    >
    </v-data-table>
    <bulk-edit-sheet />
  </v-container>
</template>


<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import BulkEditSheet from "@/incident/BulkEditSheet.vue"
import DeleteDialog from "@/incident/DeleteDialog.vue"
import IncidentCostCard from "@/incident_cost/IncidentCostCard.vue"
import IncidentParticipant from "@/incident/Participant.vue"
import IncidentPriority from "@/incident/priority/IncidentPriority.vue"
import IncidentSeverity from "@/incident/severity/IncidentSeverity.vue"
import IncidentStatus from "@/incident/status/IncidentStatus.vue"
import NewSheet from "@/incident/NewSheet.vue"
import ReportDialog from "@/incident/ReportDialog.vue"
import RouterUtils from "@/router/utils"
import TableExportDialog from "@/incident/TableExportDialog.vue"
import TableFilterDialog from "@/incident/TableFilterDialog.vue"
import WorkflowRunModal from "@/workflow/RunModal.vue"
import ProjectCard from "@/project/ProjectCard.vue"
import IncidentType from "@/incident/type/IncidentType.vue"
import AiResolutionSummaryDialog from '@/incident/AiResolutionSummaryDialog.vue'
import IncidentEventGraphMin from "@/event/IncidentEventGraphMin.vue"

export default {
  name: "IncidentTable",

  components: {
    BulkEditSheet,
    DeleteDialog,
    IncidentCostCard,
    IncidentParticipant,
    IncidentPriority,
    IncidentSeverity,
    IncidentStatus,
    NewSheet,
    ReportDialog,
    TableExportDialog,
    TableFilterDialog,
    WorkflowRunModal,
    ProjectCard,
    IncidentType,
    AiResolutionSummaryDialog,
    IncidentEventGraphMin,
  },

  props: {
    name: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      headers: [
        { text: "Name", value: "name", align: "left", width: "10%" },
        { text: "Title", value: "title", sortable: false },
        { text: "Status", value: "status" },
        { text: "Type", value: "incident_type.name" },
        { text: "Severity", value: "incident_severity.name", width: "10%" },
        { text: "Priority", value: "incident_priority.name", width: "10%" },
        { text: "Project", value: "project.name", sortable: true },
        { text: "Reported At", value: "reported_at" },
        { text: "Closed At", value: "closed_at" },
        { text: "", value: "data-table-actions", sortable: false, align: "end" },
      ],
      showEditSheet: false,
      dialog: false,
      currentStatus: 'All',
      statuses: [ // Define your statuses here
        { label: 'Active', value: 'Active', color: 'error', icon: 'mdi-alert' },
        { label: 'Stable', value: 'Stable', color: 'warning', icon: 'mdi-progress-check' },
        { label: 'Closed', value: 'Closed', color: 'success', icon: 'mdi-check-circle' },
        { label: 'All', value: 'All', color: 'secondary', icon: 'mdi-filter' },
      ],
    }
  },

  computed: {
    ...mapFields("incident", [
      "table.loading",
      "table.options.descending",
      "table.options.filters",
      "table.options.filters.commander",
      "table.options.filters.incident_priority",
      "table.options.filters.incident_severity",
      "table.options.filters.incident_type",
      "table.options.filters.project",
      "table.options.filters.reported_at",
      "table.options.filters.reporter",
      "table.options.filters.status",
      "table.options.filters.tag",
      "table.options.filters.tag_type",
      "table.options.itemsPerPage",
      "table.options.page",
      "table.options.q",
      "table.options.sortBy",
      "table.rows.items",
      "table.rows.selected",
      "table.rows.total",
      "updateLoading",
    ]),
    ...mapFields("route", ["query"]),
    ...mapFields("auth", ["currentUser.projects"]),

    defaultUserProjects: {
      get() {
        let d = null
        if (this.projects) {
          let d = this.projects.filter((v) => v.default === true)
          return d.map((v) => v.project)
        }
        return d
      },
    },
    organizationSlug() {
      return window.localStorage.getItem("organization")
    },
  },

  methods: {
    ...mapActions("incident", ["getAll", "showNewSheet", "showDeleteDialog", "showReportDialog", "markStable", "markClosed"]),
    ...mapActions("workflow", ["showRun"]),
    setCurrentStatus(status) {
      this.currentStatus = status
      this.status = [status === 'All' ? '' : status]
    },
    showIncidentEditSheet(item) {
      this.$router.push({ name: "IncidentTableEdit", params: { name: item.name } })
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler: function (newVal) {
        this.showEditSheet = newVal.meta && newVal.meta.showEditSheet
      },
    },
  },

  created() {
    this.filters = {
      ...this.filters,
      ...RouterUtils.deserializeFilters(this.query),
      project: this.defaultUserProjects,
    }

    this.getAll()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [
        vm.descending,
        vm.incident_priority,
        vm.incident_severity,
        vm.incident_type,
        vm.itemsPerPage,
        vm.project,
        vm.q,
        vm.reported_at.end,
        vm.reported_at.start,
        vm.sortBy,
        vm.status,
        vm.tag,
        vm.tag_type,
      ],
      () => {
        this.page = 1
        RouterUtils.updateURLFilters(this.filters)
        this.getAll()
      }
    )
  },
}
</script>

<style scoped>
a {
  color: white;
}
</style>
